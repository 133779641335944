(function () {

    $(document)
        .offOn('period-selected', '#admin-pending-index .period-select', function (event, periodId, existing) {
                var newHref = "/admin/periods/" + periodId + "/pending";
                Turbolinks.visit(newHref)
                //document.location = newHref;
            }
        )
        .offOn('click','#pending-reminder-btn', function(e) {
            var that = $(this)
            var selectedCount = parseInt($('.total-recs').text());
            var msg = 'Are you sure you want to send a reminder email to the ' + selectedCount + ' displayed venue' + (selectedCount == 1  ? '?' : 's?');
            that.attr('data-confirm', msg)
        })

})();